<template>
  <div>
    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      :nudge-width="150"
    >
      <!-- <div class="white accent-2 pink--text text-center py-2" style="font-size: large;"> <span class="mr-2">ใบที่</span>  {{ id }}</div>
    <v-divider></v-divider> -->
      <v-list dense>
        <v-list-item link @click="menuActionClick('edit')">
          <v-list-item-icon>
            <v-icon color="success" class="py-1">mdi-magnify</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="py-1" style="font-size: large"
              >{{ getViewText() }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="menuActionClick('detail')">
          <v-list-item-icon>
            <v-icon color="blue" class="py-1">mdi-note-text</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="py-1" style="font-size: large"
              >รายละเอียด</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item link @click="menuActionClick('del')" v-if="Status == 0">
          <v-list-item-icon>
            <v-icon class="py-1" color="red">mdi-delete-forever</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="py-1" style="font-size: large"
              >ลบ</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider v-if="Status == 0"></v-divider>

        <v-list-item link @click="menuActionClick('exit')">
          <v-list-item-icon>
            <v-icon color="amber darken-4" class="py-1"
              >mdi-location-exit</v-icon
            >
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="py-1" style="font-size: large"
              >ออก</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "PopupMenu",

  //   props: [
  //     "Status", //เปิดใช้งาน ระงับ
  //   ],

  methods: {
    Show(id,cus_id, Status) {
      this.showMenu = true;
      this.id = id;
      this.cus_id = cus_id;
      this.Status = Status;
    },
    Hide(x, y) {
      this.x = x;
      this.y = y;
    },
    menuActionClick(menu) {
      switch (menu) {
        // case "cus":
        //   this.$router.push({
        //     path: "agent_data",
        //     query: { line_id: this.id },
        //   });
        //   //this.$router.push("/Agent_Data");
        //   break;
        case "del":
          this.$emit("del", this.id);
          break;
        case "detail":
          alert("detail");
          break;
        case "edit":
          this.$router.push({
            path: "Page",
            query: {
              CusID: this.cus_id ,
              PageNumber: this.id,
            },
          });
          break;
        case "block":
          alert("block");
          break;
        case "exit":
          this.showMenu = false;
          break;
      }
    },
    getViewText(){
        //ลบ=-1 /รอส่ง=0/ส่งแล้ว=1/ ตีกลับ=2/ถูกรางวัล=3/จ่ายแล้ว=4
        if(this.Status==0) return "ดู / แก้ไข ";
        
        return "ดู";
    }
  },
  data: () => ({
    id: null,
    showMenu: false,
    x: 0,
    y: 0,
    PageNumber: 0,
    Status: 0, //0=เปิด 1=ปิด
  }),
};
</script>

<style scoped>
/* ::v-deep v-list-item-title {
  font-size: 4.3vw !important;
} */
* {
  /* font-size: 4vw !important;  */
  /* ให้ขนาด font เป็น 2% ของความกว้างของหน้าจอ */
}
</style>
