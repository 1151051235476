<template>
  <v-container fluid grid-list-xs fill-height class="container">
    <v-layout
      row
      wrap
      class="layout header_bg"
      v-resize="updateDataTableHeight"
      fluid
    >
      <v-flex xs12 class="row_title">
        <v-layout
          v-if="CusID == '001'"
          row
          style="
            flex-flow: row;
            background-color: GhostWhite;
            padding-top: 10;
            font-weight: bold;
          "
          class="grey lighten-5"
        >
          <v-flex
            xs4
            class="text-left ml-1 pl-0 mb-2 mt-2"
            style="text-decoration: underline"
          >
            {{ getAbbrPeriodThai(PeriodDT) }}
            <v-icon color="pink lighten-1" class="ml-1"
              >mdi-arrow-down-drop-circle</v-icon
            >
          </v-flex>
          <v-flex
            xs8
            class="text-right mr-1 mt-2 pink--text text--darken-1"
            v-if="CloseTime != ''"
          >
            ปิดรับ {{ getAbbrPeriodThai(CloseTime.split(" ")[0]) }} เวลา
            {{ CloseTime.split(" ")[1].split(":")[0] }}:{{
              CloseTime.split(" ")[1].split(":")[1]
            }}
            น.</v-flex
          >
        </v-layout>
        <v-layout
          row
          style="
            flex-flow: row;
            background-color: GhostWhite;
            padding-top: 10;
            font-weight: bold;
          "
          class="grey lighten-5"
          v-else
        >
          <v-flex xs4 class="text-left ml-1 pl-0 mb-2 mt-2">
            <!-- style="text-decoration: underline" -->
            <v-btn icon @click="OnBackToCustomer()">
              <v-icon color="pink lighten-1" large
                >mdi-arrow-left-bold-circle</v-icon
              >
            </v-btn>
            ย้อนกลับ
          </v-flex>
          <v-flex xs8 class="text-right mr-1 mt-2 pink--text text--darken-1">
            ชื่อลูกค้า</v-flex
          >
        </v-layout>
        <v-divider class="pink lighten-1"></v-divider>
        <v-layout
          row
          style="background-color: GhostWhite; flex-flow: row"
          class="py-2"
        >
          <v-flex xs12 class="text-left">
            <span class="pl-1"
              >รหัส {{ HostID }} {{ LineID }} {{ AgentID }}</span
            >
          </v-flex>
          <v-flex xs12 class="text-right"> เครดิต 0/10000</v-flex>
        </v-layout>
        <v-divider></v-divider>
        <v-layout
          row
          style="background-color: GhostWhite; flex-flow: row"
          class="py-2"
        >
          <v-flex xs7 class="text-left"
            ><span class="pl-1" v-if="WaitingSend_Count > 0"
              >({{ WaitingSend_Count }}) รอส่ง |</span
            >
            <span class="pl-1" v-if="SendBack_Count > 0"
              >({{ SendBack_Count }}) ตีกลับ |</span
            >
            <span class="pl-1" v-if="Won_Count > 0"
              >({{ Won_Count }}) ถูกรางวัล</span
            >
          </v-flex>
          <v-flex xs5 class="text-right"
            ><span class="pl-1">แสดง</span
            ><span class="pl-2">ทั้งหมด</span></v-flex
          >
        </v-layout>
        <v-divider></v-divider>
        <v-layout
          row
          style="background-color: GhostWhite; flex-flow: row"
          class="py-1"
        >
          <v-btn
            id="menu-activator"
            color="blue lighten-2"
            dark
            @click="updateDataTableHeight()"
          >
            <v-icon left> mdi-percent-circle </v-icon>
            ดูเลขอั้น
          </v-btn>

          <v-spacer></v-spacer>
          <v-btn
            :color="PeriodIsOpen === 0 ? 'grey' : 'pink lighten-2'"
            dark
            @click="PeriodIsOpen === 0 ? '' : OnAddPage()"
          >
            <v-icon left> mdi-plus-box </v-icon>
            เพิ่มโพยใหม่
          </v-btn>

          <!-- <v-flex xs7 class="text-left"><span class="pl-1">ใบที่ 1</span><span class="pl-2">(รอส่ง)</span>  </v-flex>
          <v-flex xs5 class="text-right">รวม 100 ลอย 100 </v-flex> -->
        </v-layout>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 class="row_content" ref="tableDiv">
        <Table :tableheight="dataTableHeight" :CusID="CusID"></Table>
      </v-flex>
      <v-flex xs12 class="row_footer">
        <div>
          {{ PageSendCount }} ใบ | ยอดลอย {{ add_comma(PriceLoyAll) }} บาท |
          ยอดรวม {{ add_comma(PriceSumAll) }} บาท
        </div>
      </v-flex>
    </v-layout>
    <HpDialog />
  </v-container>

  <!-- <div>ตาราง</div> -->

  <!-- <div>ยอดลอย</div> -->
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";
import Table from "@/components/Pages/Main/Table";
import HpDialog  from "@/components/Main/HpDialog";

export default {
  name: "Pages_Main",
  mixins: [globalFunctionMixin],
  components: {
    Table,
    HpDialog,
    //component name,
  },
  mounted() {
    this.ckLogin();
    window.scrollTo(0, 0);
    // console.log('CusID');
    // console.log(this.$route.query.CusID);
    // console.log(typeof this.$route.query.CusID);
    if (typeof this.$route.query.CusID == "undefined") {
      console.log("undefined");
    } else {
      this.CusID = this.$route.query.CusID;
    }

    this.GetDataTable();

    setTimeout(() => {
      this.updateDataTableHeight();
    }, 1000);
  },
  computed: {
    PageSendCount() {
      //   console.log("update lines_data");
      return this.$store.getters["pages/PageSendCount"];
    },
    PriceSumAll() {
      return this.$store.getters["pages/PriceSumAll"];
    },
    PriceLoyAll() {
      return this.$store.getters["pages/PriceLoyAll"];
    },
  },
  methods: {
    async GetDataTable() {
      let parameter = {
        to: "Pages",
        CusID: this.CusID,
        method: "get_all",
        PeriodDT: this.PeriodDT,
      };

      await this.$store.dispatch("pages/TransferData", parameter).then(
        (response) => {
          //   this.$router.push("/Dashboard");
          console.log(response);
          let obj1 = response.PageStatus.find((o) => o.status === "0");
          let obj2 = response.PageStatus.find((o) => o.status === "2");
          let obj3 = response.PageStatus.find((o) => o.status === "3");
          let obj4 = response.PageStatus.find((o) => o.status === "4");
          //   console.log(obj4.count);
          if (obj1 == null) this.WaitingSend_Count = 0;
          else this.WaitingSend_Count = parseInt(obj1.count);

          if (obj2 == null) this.SendBack_Count = 0;
          else this.SendBack_Count = parseInt(obj2.count);

          if (obj3 == null) this.Won_Count = 0;
          else this.Won_Count = parseInt(obj3.count);

          if (obj4 != null) this.Won_Count += parseInt(obj4.count);

          //response.Period
          this.CloseTime = response.Period.CloseTime;
          this.PeriodStatus = parseInt(response.Period.Status);
          this.PeriodIsOpen = parseInt(response.Period.IsOpen);
          //   this.updateDataTableHeight();
        },
        (error) => {
          //   this.updateDataTableHeight();
          //   switch (error) {
          //     case "PIN_INCORRECT":
          //       this.$refs.pincodeInput.resetPincode();
          //       this.$alert("รหัสไม่ถูกต้อง.", "ผิดพลาด", "error").then(() => {});
          //       break;
          //     default:
          //       alert(error);
          //       break;
          //   }
        }
      );
    },
    updateDataTableHeight() {
      // console.log("test1");
      try {
        this.dataTableHeight = this.$refs.tableDiv.clientHeight;
        console.log(this.dataTableHeight);
      } catch (err) {
        // console.log("test2");
        console.log(err);
        //   document.getElementById("demo").innerHTML = err.message;
      }
    },
    OnAddPage() {
      //check period status

      this.Sending = true;
      this.doAddPage();
      //   this.$router.push({
      //     path: "Page",
      //     query: {
      //       PageNumber: this.PageNumber,
      //     },
      //   });
    },
    async doAddPage() {
      if (this.Sending == false) return;
      if (this.PeriodIsOpen == 0) return;
      let parameter = {
        to: "Pages",
        method: "add_new",
        CusID: this.CusID,
        PeriodDT: this.PeriodDT,
      };

      await this.$store.dispatch("pages/TransferData", parameter).then(
        (response) => {
          //   this.$router.push("/Dashboard");
          console.log(response);
          this.Sending = false;

          this.$router.push({
            path: "Page",
            query: {
              PageNumber: response.PageNumber,
              CusID: this.CusID,
            },
          });
        },
        (error) => {
          this.Sending = false;
        }
      );
    },
    GetAddPageColor() {
      if (this.PeriodIsOpen == 0) return "grey lighten-2";
      return "pink lighten-2";
    },
    OnBackToCustomer() {
      this.$router.push({
        path: "Customers",
        query: {
          Tab: 3,
          CusID: this.CusID,
        },
      });
    },
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),
    CusID: "001",

    CloseTime: "",
    PeriodStatus: 0,
    PeriodIsOpen: 0,

    WaitingSend_Count: 0,
    SendBack_Count: 0,
    Won_Count: 0,

    dataTableHeight: 0,

    Sending: false,
  }),
};
</script>

<style scoped>
.container {
  background-color: red;
  flex-grow: 1;
  flex-shrink: 0;
}
.layout {
  flex-flow: column;
  padding: 2px;
}
.row_title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 100%;
  flex: 0 1;
}
.row_content {
  background-color: darkseagreen;
  flex: 3 0px;
  flex-grow: 1;
  flex-shrink: 0;
  overflow-y: auto;
}
.row_footer {
  background-color: rgb(245, 253, 255);
  flex: 0 1 40px;
  margin-bottom: 0;
  padding-bottom: 0;
  border-top: 2px solid LightBlue;
  color: MediumAquaMarine;
  font-size: 4vw;
  font-weight: bold;
  padding-top: 1% !important;
  padding-right: 5px !important;
  text-align: right;
}
.header_bg {
  background-color: whitesmoke;
  color: black;
  font-size: 4.4vw;
  padding-top: 0;
}

.line {
  height: 2px;
  width: 100%;
  background-color: black;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clickable {
  cursor: pointer;
}
</style>
