<template>
  <v-layout
    style="
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -o-user-select: none;
      padding: 0;
    "
    fluid
    unselectable="on"
    onselectstart="return false;"
    onmousedown="return false;"
    id="printSection"
  >
    <v-data-table
      :headers="headers"
      :items="pages_data"
      :items-per-page="10"
      item-key="id"
      single-select
      class="elevation-1"
      hide-default-footer
      disable-pagination
      mobile-breakpoint="0"
      :height="tableheight"
      fixed-header
      dense
    >
      <!-- :class="{'h4': $vuetify.breakpoint. mdAndUp}" -->
      <template slot="no-data"
        ><span class="red--text">ว่างเปล่า</span> กด
        <span class="blue--text">เพิ่มโพยใหม่</span> เพื่อ
        <span class="green--text">สร้างโพย</span></template
      >
      <template v-slot:item="{ item }">
        <tr
          @click="row_click($event, item)"
          :class="{ selected_class: item.PageNumber === selectedId }"
        >
          <!-- <td :class="id_column">{{ item.id }}</td> -->

          <td class="id_column">
            <span class="lime--text text--darken-3"
              >{{ item.PageNumber }}.</span
            >
          </td>
          <td align="center" :class="getStatusColor(item.Status)">
            {{ GetStatusText(item.Status) }}
          </td>
          <td align="end">{{ add_comma(item.PriceSumLoy) }}</td>
          <td class="text-end">{{ add_comma(item.PriceSum) }}</td>
        </tr>
      </template>
    </v-data-table>
    <PopupMenu ref="PopupMenu" @del="OnDelete" />
  </v-layout>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

import PopupMenu from "@/components/Pages/Main/Popup_Menu";

export default {
  name: "PagesTable",
  mixins: [globalFunctionMixin],
  components: {
    PopupMenu,
  },
  props: {
    tableheight: {
      type: Number,
      default: 500, //default v-data-table footer height
    },
    CusID: {
      type: String,
      default: "001", //default v-data-table footer height
    },
  },
  mounted() {
    // this.ckLogin();
    // window.scrollTo(0, 0);
    // this.GetDataTable();
  },
  computed: {
    // //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    pages_data() {
      return this.$store.getters["pages/PagesList"];
    },
  },
  methods: {
    GetStatusText(status) {
      const statusText = [
        "ลบ",
        "รอส่ง",
        "ส่งแล้ว",
        "ตีกลับ",
        "ถูกรางวัล",
        "จ่ายแล้ว",
      ];
      return statusText[parseInt(status) + 1];
    },
    getStatusColor(status) {
      const statusColor = [
        "red--text",
        "yellow--text text--darken-4",
        "green--text text--darken-1",
        "red--text",
        "light-blue--text",
        "indigo--text",
      ];
      return statusColor[parseInt(status) + 1];
    },
    row_click: function (e, item) {
      if (this.selectedId == item.PageNumber) {
        this.popup_menu(e, item);
      }

      this.selectedId = item.PageNumber;
      this.$set(item, "selected", true);
    },
    popup_menu: function (e, item) {
      e.preventDefault();
      this.$refs.PopupMenu.Hide(e.clientX, e.clientY);
      this.$nextTick(() => {
        this.$refs.PopupMenu.Show(item.PageNumber, this.CusID, item.Status);
        this.selectedId = item.PageNumber;
        //console.log(item);
      });
    },
    OnDelete(p_PageNumber) {
      this.$confirm("กด OK เพื่อยืนยันการลบโพย", "ยืนยัน", "question")
        .then(() => {
          this.DoDelete(p_PageNumber);
        })
        .catch(() => {});
    },
    async DoDelete(p_PageNumber) {
      let parameter = {
        to: "pages",
        method: "del",
        PeriodDT: this.PeriodDT,
        PageNumber: p_PageNumber,
        CusID: this.CusID,
      };

      await this.$store.dispatch("pages/TransferData", parameter).then(
        (response) => {
          //   this.$router.push("/Dashboard");
        },
        (error) => {}
      );
    },
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),

    selectedId: -1,
    PageNumber: 0,
    headers: [
      { text: "ใบที่", align: "center" },
      //   { text: "ลูกค้า" ,align:"center"},
      { text: "สถานะ", align: "center" },
      { text: "ยอดลอย", align: "end" },
      { text: "ยอดรวม", align: "end" },
    ],
    // pages_data: [
    //   {
    //     PageNO: "1",
    //     Status: 159,
    //     Cus: 6.0,
    //     PriceSum: 24,
    //   },
    //   {
    //     PageNO: "2",
    //     Status: 237,
    //     Cus: 9.0,
    //     PriceSum: 37,
    //   },
    //   {
    //     PageNO: "3",
    //     Status: 262,
    //     Cus: 16.0,
    //     PriceSum: 23,
    //   },
    //   {
    //     PageNO: "4",
    //     Status: 305,
    //     Cus: 3.7,
    //     PriceSum: 67,
    //   },
    //   {
    //     PageNO: "5",
    //     Status: 356,
    //     Cus: 16.0,
    //     PriceSum: 49,
    //   },
    //   {
    //     PageNO: "6",
    //     Status: 375,
    //     Cus: 0.0,
    //     PriceSum: 94,
    //   },
    //   {
    //     PageNO: "7",
    //     Status: 392,
    //     Cus: 0.2,
    //     PriceSum: 98,
    //   },
    //   {
    //     PageNO: "8",
    //     Status: 408,
    //     Cus: 3.2,
    //     PriceSum: 87,
    //   },
    //   {
    //     PageNO: "9",
    //     Status: 452,
    //     Cus: 25.0,
    //     PriceSum: 51,
    //   },
    //   {
    //     PageNO: "10",
    //     Status: 518,
    //     Cus: 26.0,
    //     PriceSum: 65,
    //   },
    //   {
    //     PageNO: "1",
    //     Status: 159,
    //     Cus: 6.0,
    //     PriceSum: 24,
    //   },
    //   {
    //     PageNO: "2",
    //     Status: 237,
    //     Cus: 9.0,
    //     PriceSum: 37,
    //   },
    //   {
    //     PageNO: "3",
    //     Status: 262,
    //     Cus: 16.0,
    //     PriceSum: 23,
    //   },
    //   {
    //     PageNO: "4",
    //     Status: 305,
    //     Cus: 3.7,
    //     PriceSum: 67,
    //   },
    //   {
    //     PageNO: "5",
    //     Status: 356,
    //     Cus: 16.0,
    //     PriceSum: 49,
    //   },
    //   {
    //     PageNO: "6",
    //     Status: 375,
    //     Cus: 0.0,
    //     PriceSum: 94,
    //   },
    //   {
    //     PageNO: "7",
    //     Status: 392,
    //     Cus: 0.2,
    //     PriceSum: 98,
    //   },
    //   {
    //     PageNO: "8",
    //     Status: 408,
    //     Cus: 3.2,
    //     PriceSum: 87,
    //   },
    //   {
    //     PageNO: "9",
    //     Status: 452,
    //     Cus: 25.0,
    //     PriceSum: 51,
    //   },
    //   {
    //     PageNO: "10",
    //     Status: 518,
    //     Cus: 26.0,
    //     PriceSum: 65,
    //   },
    // ],
  }),
};
</script>

<style scoped>
.toolbar__items {
  flex-wrap: wrap;
}
::v-deep table {
  /* background-color: #ffffff; */
  /* table-layout: fixed; */
}
::v-deep th {
  font-size: 4vw !important;
  color: rgb(88, 88, 88);
  font-weight: bold;
}

::v-deep tr:nth-child(odd) {
  background-color: #fafafa;
}
::v-deep table tbody tr td {
  font-size: 4vw !important;
  color: rgb(88, 88, 88);
  font-weight: 500;
  border-right: 1px solid rgb(236, 236, 236);
  padding: 0 0;
}
.id_column {
  /* border-right: 1px solid rgb(190, 197, 255); */
  padding: 0 0;
}

.selected_class {
  background-color: pink !important;
  /* color: white !important; */
}
</style>
